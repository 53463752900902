import React from 'react';


const N1 = () => {


    return (

        <div className='content'>  
        <br></br><br></br>
            <center>
                  
                    <br></br><br></br>

                    <br></br>
                   <h3>Release Notes</h3>
                   </center>   
                    <br></br><br></br>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    I am pleased to announce that I have made numerous improvements to the application recently, among which I would like to mention:
                    <ul className='contentText'>
                        <li> Improved security</li>
                        <li> Login page bug fixes</li>
                        <li> The ability to log in as a guest and experience all the features of the application</li>
                        <li> Support for a variety of resolutions</li>
                        <li> Document generation fixes</li>
  </ul>

<br></br>


&nbsp;&nbsp;&nbsp;&nbsp;
    The application is fully functional, and for the time being, I will be shifting my focus to the development of the mobile application, which will be a simplified version of the web application, allowing users to view the latest data collected by monitoring and data collection equipment. I hope this won't take long, and I will be able to return to the development of the web application and API soon. As a target for the next major update, I have set the following goals:
<ul className='contentText'>

    <li>Communicating the final solution for integrating all types of equipment into a single application.</li>
    <li>The ability to create new accounts. I don't want to rush this task, as I want it to be a solid feature from the start that I can rely on.</li>
    <li>Creating a form for associating equipment with the customer's account.</li>
    <li>Visual improvements to existing pages.</li>
    <li>Enhancements to the environmental parameter monitoring equipment.</li>
</ul>
&nbsp; &nbsp; &nbsp; &nbsp;

             



        </div>
        
    )

}

export default N1;