import React from 'react';


const Contact = () => {


    return (

        <div className='content'>  
        <br></br><br></br>
            <center>
                    <img src={process.env.PUBLIC_URL + '/pozaProfil.png'} width='200px' />
                    <br></br><br></br>

                    <br></br>
                    e-mail: fetti.horia@gmail.com
                    <br></br><br></br>
                    Coinbase affliate link: <a href='https://www.coinbase.com/join/LYAQZ1?src=referral-link'>https://www.coinbase.com/join/LYAQZ1?src=referral-link </a>
 </center>     
<br></br><br></br>
&nbsp; &nbsp; &nbsp; &nbsp;
That one was a joke, but if you still want to buy some crypto, the link is still valid.
Is Coinbase a crypto exchange that you can trust?
Maybe, as they certainly have some questionable dealings and their mobile application doesn't even work properly. However, unlike Binance, they don't facilitate transactions in countries with unstable situations.
Do your own research and invest at your own risk.
             



        </div>
        
    )

}

export default Contact;