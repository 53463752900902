import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import AboutMe from "./Components/AboutMe";
import Home from "./Components/Home";
import Games from "./Components/Games";
import IotProjects from "./Components/IotProjects";
import Contact from "./Components/Contact";
import ArduinoRobot1 from "./Components/IotProjectsComponents/ArduinoRobot1";
import ArduinoRobot2 from "./Components/IotProjectsComponents/ArduinoRobot2";
import './App.css';
import SmartAgriculture from "./Components/IotProjectsComponents/SmartAgriculture";
import React, { useState, useEffect } from 'react';
import DarkSouls3 from "./Components/GamesComponents/DarkSouls3";
import DarkSoulsRemastered from "./Components/GamesComponents/DarkSoulsRemastered";
import LoopHero from "./Components/GamesComponents/LoopHero";
import Phasmophobia from "./Components/GamesComponents/Phasmophobia";
import PunchClub from "./Components/GamesComponents/PunchClub";
import News from "./Components/News";
import Button from '@mui/material/Button';
import Footer from "./Components/Footer";
import $ from 'jquery';
import News1 from "./Components/NewsComponents/N1";


function App() {

  return (
    <div>
    <BrowserRouter>
    <br></br><br></br>
      <nav className="horizontal-nav">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/AboutMe">About Me</Link></li>
          <li><Link to="/Games">Game reviews</Link></li>
          <li><Link to="/IotProjects">Iot Projects</Link></li>
          <li className="flaming-text"><Link to="/News">News</Link></li>
          <li><Link to="/Contact">Contact</Link></li>
        </ul>
      </nav>


      

      <Routes>
<Route path="/" element={<Home />}/>
<Route path="/AboutMe" element={<AboutMe />}/>
<Route path="/Games" element={<Games />}/>
<Route path="/IotProjects" element={<IotProjects />}/>
<Route path="/Contact" element={<Contact />}/>
<Route path="/IotProjects/SmartAgriculture" element={<SmartAgriculture />}/>
<Route path="/IotProjects/ArduinoRobot1" element={<ArduinoRobot1 />}/>
<Route path="/IotProjects/ArduinoRobot2" element={<ArduinoRobot2 />}/>
<Route path="/Games/DarkSouls3" element={<DarkSouls3 />}/>
<Route path="/Games/DarkSoulsRemastered" element={<DarkSoulsRemastered />}/>
<Route path="/Games/LoopHero" element={<LoopHero />}/>
<Route path="/Games/Phasmophobia" element={<Phasmophobia />}/>
<Route path="/Games/PunchClub" element={<PunchClub />}/>
<Route path="/News" element={<News />}/>
<Route path="/News/N1" element={<News1 />}/>

        

    </Routes>
    </BrowserRouter>

<Footer/>
    </div>
  );
}

export default App;
