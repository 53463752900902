import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import 'font-awesome/css/font-awesome.min.css';

function Footer() {
  return (
  
    <footer className="footer">
      <br></br>
      <div>
        <p> {new Date().getFullYear()} This is a page showcasing my projects.</p>
        <br></br>
        <p> The primary project at the moment is Smart Agriculture, which can be explored here at  <a href="https://smartagriculture.horiafetti.ro" target="_blank">smartagriculture.horiafetti.ro</a></p>
        <p>If you have any proposals or suggestions, please feel free to write to me at fetti.horia@gmail.com</p>
        <br></br>
        <center>
        <ul className="footer-links">
          <li><a href="/">Home</a></li>
          <li><a href="/AboutMe">About me</a></li>
          <li><a href="/Games">Game reviews</a></li>
          <li><a href="/IotProjects">IoT projects</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
</center>
<br></br>
<div className="social-icons">
          <a href="https://www.facebook.com/horia.fetti/" target="_blank" rel="noopener noreferrer">
          <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://www.linkedin.com/in/horia-fetti/" target="_blank" rel="noopener noreferrer">
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>
        </div>


      </div>
      <br></br><br></br>
    </footer>
  );
}

export default Footer;