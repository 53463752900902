import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import React from 'react';


    const projects = [
        {
          title: 'Smart Agriculture Web Demo',
          imagePath: process.env.PUBLIC_URL + '/SM_demo.png',
          link: '/News/N1',
          description: "05.10.2023",
        },
    
        // Adaugă aici alte proiecte IoT în același format
      ];


    const News = () => {
        return (
          <div className="content">

<div className="contentText">
            <br></br>
            &nbsp; &nbsp; &nbsp; &nbsp;
            {/* I consider video games a form of art that resonates with me; I greatly enjoy the various universes in which many of the actions of the games I appreciate are set. 
            The first thing that catches my attention in a video game is its graphic style, its consistency, and the color palette used. 
            Equally important is the gameplay mechanics and the variety of approaches I can take in playing the game. 
            My inclination towards Souls-like games can also be observed for these reasons.
            <br></br><br></br>
            &nbsp; &nbsp; &nbsp; &nbsp;
            Of course, I will provide more details in the reviews of each game, reviews that I will only create for the games in which I've spent many hours and extensively explored. 
            I will also take into account the opinions of friends with whom I've had discussions on this subject.
            <br></br><br></br> */}
</div>
<ul style={{ listStyleType: 'none' }}>

                {projects.map((project, index) => (
                  <li key={index}>
                    {/* <Link to={project.link}>
                      <img src={project.imagePath} alt={project.title} />
                      <p>{project.title}</p>
                    </Link> */}
                                <div class="card-container">
<img src={process.env.PUBLIC_URL + project.imagePath} style={{ maxWidth: '40%', height: 'auto' }} alt={project.title}/>
        <div class="profile-info">
            <div class="profile-name">{project.title}</div>
            <div class="profile-description">
                <p>{project.description}</p>
            </div>
            <a class="profile-button" href={project.link}>></a>
        </div>
    </div>
                 <br></br> </li>
                ))}
              </ul>

          </div>
        );
      };

export default News;